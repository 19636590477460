import './App.css'
import 'animate.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="animate__animated animate__fadeInDown logo">
          <spam>traum</spam><spam className="red">.</spam>
        </p>
      </header>
    </div>
  );
}

export default App;
